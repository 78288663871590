import type { Consent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import type { ConversionTrackingModel } from "@/hooks/tracking/emitter/tracking-emitter.types";

export function kilkayaConversionTracking(
  model: ConversionTrackingModel,
  consent: Consent,
) {
  if (typeof kilkaya === "undefined") {
    return;
  }

  const { event: conversionEvent } = model;

  if (consent.submitted && typeof kilkaya !== "undefined") {
    kilkaya.pageview({
      cntTag: conversionEvent,
      conversion: 1,
      nopv: 1,
    });
  }
}
