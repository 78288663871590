"use client";

import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import type { ReactNode } from "react";
function ProgressBarProvider({
  children
}: {
  readonly children: ReactNode;
}) {
  return <>
      {children}
      <ProgressBar color="hsl(0, 0%, 60%)" height="3px" options={{
      showSpinner: false
    }} shallowRouting={true} data-sentry-element="ProgressBar" data-sentry-source-file="ProgressBarProvider.tsx" />
    </>;
}
export default ProgressBarProvider;