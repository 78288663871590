import type { Consent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import type { PageViewTrackingModel } from "@/hooks/tracking/page-view/page-view-tracking.model";
import type { UserModel } from "@/services/api/user/models/user.model";
import { mapToKilkayaPageViewModel } from "../mappers/map-to-kilkaya-page-view-model";

type KilkayaPageTrackingProps = {
  readonly model: PageViewTrackingModel;
  readonly user: UserModel;
  readonly consent: Consent;
};

export function kilkayaPageTracking({
  consent,
  model,
  user,
}: KilkayaPageTrackingProps) {
  if (!consent.submitted) {
    return;
  }

  window.k5aMeta = mapToKilkayaPageViewModel({
    consent,
    model,
    user,
  });

  document.dispatchEvent(new Event("K5A:SpaReady"));
}
