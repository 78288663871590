"use client";

import type { ClickTrackingModel } from "@/hooks/tracking/emitter/tracking-emitter.types";

export function kilkayaClickTracking(model: ClickTrackingModel) {
  if (typeof kilkaya === "undefined") {
    return;
  }

  const { position, toUrl, url } = model;

  kilkaya.click({
    position,
    toUrl,
    url,
  });
}
