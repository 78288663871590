"use client";

import { useSiteConfiguration } from "@/contexts/site-configuration/site-configuration.context";
import Script from "next/script";
import { useEffect } from "react";
function grantConsentWithUrlParam() {
  /* global Cookiebot -- Cookiebot is injected by an external script. */
  if (typeof Cookiebot !== "undefined" && Cookiebot.getDomainUrlParam("CookiebotScan") === "true") {
    Cookiebot.setOutOfRegion();
  }
}
export const evaulateScripts = () => {
  if (typeof Cookiebot !== "undefined" && typeof Cookiebot.runScripts === "function") {
    Cookiebot.runScripts();
  }
};
export function CookiebotScript() {
  const {
    tracking
  } = useSiteConfiguration();
  const cookiebotId = tracking?.cookiebotId;
  useEffect(() => {
    window.addEventListener("CookiebotOnDialogInit", grantConsentWithUrlParam);
    return function cleanUp() {
      window.removeEventListener("CookiebotOnDialogInit", grantConsentWithUrlParam);
    };
  }, []);
  return <>
      <Script dangerouslySetInnerHTML={{
      // biome-ignore lint/style/useNamingConvention: This script is provided by Cookiebot
      __html: `

 {
 "Frameworks": {
 "IABTCF2": {
 "AllowedVendors": [394,755,50,612,638,624,79,597,68,111,328],
 "AllowedGoogleACVendors": [],
 "AllowedPurposes": [1,2,3,4,5,6,7,8,9,10],
 "AllowedSpecialPurposes": [1,2],
 "AllowedFeatures": [1,2,3],
 "AllowedSpecialFeatures": [],
 "VendorRestrictions": [
 {
 "//vendorName": "AudienceProject Aps",
 "VendorId": 394,
 "DisallowPurposes": []
 },
 {
 "//vendorName": "Google Advertising Products",
 "VendorId": 755,
 "DisallowPurposes": [],
 "DisallowFeatures": []
 },
 {
 "//vendorName": "Adform",
 "VendorId": 50,
 "DisallowPurposes": [],
 "DisallowFeatures": []
 },
 {
 "//vendorName": "Adnami Aps",
 "VendorId": 612,
 "DisallowPurposes": []
 },
 {
 "//vendorName": "Passendo Aps",
 "VendorId": 638,
 "DisallowPurposes": []
 },
 {
 "//vendorName": "Norstat AS",
 "VendorId": 624,
 "DisallowPurposes": []
 },
 {
 "//vendorName": "MediaMath, Inc.",
 "VendorId": 79,
 "DisallowPurposes": [],
 "DisallowFeatures": []
 },
 {
 "//vendorName": "Better Banners A/S",
 "VendorId": 597,
 "DisallowPurposes": []
 },
 {
 "//vendorName": "Sizmek by Amazon",
 "VendorId": 68,
 "DisallowPurposes": [],
 "DisallowFeatures": []
 },
 {
 "//vendorName": "Showheroes SE",
 "VendorId": 111,
 "DisallowPurposes": []
 },
 {
 "//vendorName": "Gemius SA",
 "VendorId": 328,
 "DisallowPurposes": []
 }
 ],
 "GoogleACVendorsRestrictions": [
 ]
 }
 }
 }
 `
    }} data-cookieconsent="ignore" id="CookiebotConfiguration" type="application/json" data-sentry-element="Script" data-sentry-source-file="CookiebotScript.component.tsx" />

      <Script dangerouslySetInnerHTML={{
      // biome-ignore lint/style/useNamingConvention: This script is provided by Cookiebot
      __html: `
 window.addEventListener("CookiebotOnDialogDisplay", function () {
 const c = Cookiebot.dialog.userLanguage
 const title = { 
 en: "It's your choice", 
 da: "Det er dit valg", 
 nb: "Deg og dine valg", 
 de: "Du hast die Wahl",
 sv: "Det är ditt val"
 }
 const t = document.getElementById("CybotCookiebotDialogBodyContentTitle")

 if (!title[c] || !t) return;
 t.innerText = title[c];
 });
 `
    }} data-cookieconsent="ignore" id="CookiebotBannerConf" data-sentry-element="Script" data-sentry-source-file="CookiebotScript.component.tsx" />

      <Script dangerouslySetInnerHTML={{
      // biome-ignore lint/style/useNamingConvention: This script is provided by Cookiebot
      __html: `
window.addEventListener("CookiebotOnConsentReady", function () {
 var c = Cookiebot,
 d = document,
 cookies = d.cookie.split(";"),
 consentCookie = "",
 consentDate = c.consentUTC,
 now = new Date();
 
 if (typeof consentDate === "undefined" || consentDate === null) return;
 var consentExpiry = new Date(consentDate.setMonth(consentDate.getMonth() + 1));
 for (var i = 0; i < cookies.length; i++){
if (/^CookieConsent/g.test(cookies[i].trim()))
 consentCookie = cookies[i].trim(); }
 if (consentCookie !== "" && now < consentExpiry)
 d.cookie = consentCookie + ";expires=" + consentExpiry.toUTCString()+";SameSite=None;path=/;Secure;";
});
 `
    }} data-cookieconsent="ignore" id="CookiebotConsentExpiration" data-sentry-element="Script" data-sentry-source-file="CookiebotScript.component.tsx" />

      <Script id="CookieBot" src={`https://consent.cookiebot.com/uc.js?cbid=${cookiebotId}&framework=TCFv2.2&bulkconsentmode="disabled"`} data-sentry-element="Script" data-sentry-source-file="CookiebotScript.component.tsx" />
    </>;
}