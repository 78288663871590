"use client";

import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import Script from "next/script";
import { useEffect, useState } from "react";
type SleeknoteEvent = Event & {
  readonly data: {
    readonly type: string;
  };
};
export function Sleeknote() {
  const consent = useCookiebotConsent();
  const [isSleeknoteLoaded, setIsSleeknoteLoaded] = useState(false);
  const [marketingOrStatisticsConsentGiven, setMarketingOrStatisticsConsentGiven] = useState(false);
  useEffect(() => {
    setMarketingOrStatisticsConsentGiven(consent.marketing || consent.statistics);
    document.addEventListener("sleekNote", sleeknoteIsLoaded as EventListener, false);
    function sleeknoteIsLoaded(sleeknoteEvent: SleeknoteEvent) {
      if (sleeknoteEvent.data.type === "load") {
        setIsSleeknoteLoaded(true);
      }
    }
    if (typeof window.sleeknoteMarketingConsent === "undefined") {
      window.sleeknoteMarketingConsent = consent.marketing;
    }
    if (typeof window.sleeknoteAnalyticsConsent === "undefined") {
      window.sleeknoteAnalyticsConsent = consent.statistics;
    }
    if (isSleeknoteLoaded && typeof window.sleeknoteMarketingConsent !== "boolean" && typeof window.sleeknoteMarketingConsent !== "undefined") {
      window.sleeknoteMarketingConsent.set(consent.marketing);
    }
    if (isSleeknoteLoaded && typeof window.sleeknoteAnalyticsConsent !== "boolean" && typeof window.sleeknoteAnalyticsConsent !== "undefined") {
      window.sleeknoteAnalyticsConsent.set(consent.statistics);
    }
    return function cleanUp() {
      document.removeEventListener("sleekNote", sleeknoteIsLoaded as EventListener);
    };
  }, [consent, isSleeknoteLoaded]);
  return marketingOrStatisticsConsentGiven ? <Script data-cookieconsent="ignore" id="Sleeknote" src="//sleeknotecustomerscripts.sleeknote.com/37003.js" strategy="lazyOnload" type="text/javascript" data-sentry-element="Script" data-sentry-component="Sleeknote" data-sentry-source-file="Sleeknote.component.tsx" /> : null;
}