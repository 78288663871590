import {
  archivePageLink,
  latestPageLink,
} from "@/components/page-links.constants";
import type { Consent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import type { PageViewTrackingModel } from "@/hooks/tracking/page-view/page-view-tracking.model";
import type { UserModel } from "@/services/api/user/models/user.model";
import type { KilkayaPageViewModel } from "../models/kilkaya-page-view.model";

type MapToKilkayaPageViewModelProps = {
  readonly consent: Consent;
  readonly user: UserModel;
  readonly model: PageViewTrackingModel;
};

export function mapToKilkayaPageViewModel({
  consent,
  model: {
    contentAuthor,
    contentModifiedTime,
    contentPublishTime,
    conversion,
    description,
    imageUrlPath,
    pageRestrictedType,
    pageType,
    referrer,
    sectionName,
    title,
    url,
  },
  user,
}: MapToKilkayaPageViewModelProps): KilkayaPageViewModel {
  return {
    author: contentAuthor?.map((author) => author) ?? null,
    cntTag: null,
    consent: consent.marketing ? 1 : 0,
    contentTag: null,
    conversion: conversion ? 1 : 0,
    description,
    image: imageUrlPath,
    login: user.loggedIn ? 1 : 0,
    modifiedtime: contentModifiedTime,
    paid: pageRestrictedType === "paid" ? 1 : 0,
    paywall: mapPaywallType(pageRestrictedType, user.hasAccess),
    publishtime: contentPublishTime,
    referrer,
    section: sectionName,
    subscriber: user.hasAccess ? 1 : 0,
    subsection: null,
    tag: null,
    title,
    type: mapPageType(pageType, url),
    url,
  };
}

export function mapPaywallType(
  pageRestrictedType: string,
  hasAccess: boolean,
): KilkayaPageViewModel["paywall"] {
  switch (pageRestrictedType) {
    case "free":
      return "open";
    case "paid":
      return hasAccess ? "open" : "hard";
    default:
      return "open";
  }
}

export function mapPageType(
  pageType: string,
  currentURL: string,
): KilkayaPageViewModel["type"] {
  const path = new URL(decodeURIComponent(currentURL)).pathname;
  switch (path) {
    case "/":
      return "frontpage";
    case archivePageLink:
      return "archive";
    case latestPageLink:
      return "latest";
    default:
      switch (pageType) {
        case "art":
          return "article";
        case "sec":
          return "section";
        default:
          return "website";
      }
  }
}
